// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-picture-js": () => import("/opt/build/repo/src/templates/picture.js" /* webpackChunkName: "component---src-templates-picture-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-drawings-js": () => import("/opt/build/repo/src/pages/drawings.js" /* webpackChunkName: "component---src-pages-drawings-js" */),
  "component---src-pages-etchings-js": () => import("/opt/build/repo/src/pages/etchings.js" /* webpackChunkName: "component---src-pages-etchings-js" */),
  "component---src-pages-exhibitions-js": () => import("/opt/build/repo/src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paintings-js": () => import("/opt/build/repo/src/pages/paintings.js" /* webpackChunkName: "component---src-pages-paintings-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

